import {If} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {media, convertMDContent} from '../../../utils'
import Typography from '../../atoms/Typography'


const StyledDesktopDescriptionContainer = styled.div`
  margin-left: 6.25rem;
  width: 40%;

  ${media.down('mobile')(css`
  margin-left: 3.25rem;
    width: 80%;
  `)}
`

const StyledDescription = styled(Typography)`
  padding: 1.5rem 0;

  h6 {
    padding-top: 1.5rem;
  }

  ul {
    &:not(:first-child) {
      padding: 1rem 0;
    }

    li {
      padding-bottom: 0.5rem;
    }
  }

  p {
    padding: 0.75rem 0;
  }

  a {
    color: ${({theme}) => theme.colors.secondary};
  }
`

const CookiesContent = ({children}) => {
  return (
    <If condition={!isEmpty(children)}>
      <StyledDesktopDescriptionContainer>
        <StyledDescription type="div" dangerouslySetInnerHTML={{__html: convertMDContent(children)}} />
      </StyledDesktopDescriptionContainer>
    </If>
  )
}

CookiesContent.propTypes = {
  children: PropTypes.string.isRequired,
}

export default CookiesContent
