import {If} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {media} from '../../../utils'


const StyledCookiesContainer = styled.div`
  margin: 6.125rem;

  ${media.down('mobile')(css`
    margin: 3.75rem 1.5rem;
  `)}
`

const CookiesContainer = ({children}) => {
  return (
    <If condition={!isEmpty(children)}>
      <StyledCookiesContainer>{children}</StyledCookiesContainer>
    </If>
  )
}

CookiesContainer.propTypes = {
  children: PropTypes.array.isRequired,
}

export default CookiesContainer
